import colors from 'assets/scss/_themes-vars.module.scss';

const miniVariantWidth = '90px';

export const navBarStyle = {
  backgroundColor: '#fff',
  color: '#000000',
  boxShadow: 0,
};

export const getBoxWrapperStyles = (isSidebarCollapsed, drawerWidth) => ({
  transition: '0.2s ease-in-out',
  padding: '20px',
  marginY: '15px',
  borderRadius: '20px',
  backgroundColor: '#f3f5fa',
  minHeight: '100vh',
  flex: 1,
  overflowY: 'auto',
  marginLeft: isSidebarCollapsed ? miniVariantWidth : drawerWidth,
  '@media screen and (max-width: 768px)': {
    marginLeft: isSidebarCollapsed ? miniVariantWidth : 0,
  },
});

export const getSidebarWrapperStyles = (isSidebarCollapsed, drawerWidth) => ({
  position: 'fixed',
  top: '50px',
  left: '0',
  bottom: '0',
  marginTop: '20px',
  paddingBottom: '20px',
  background: '#fff',
  transition: '0.2s ease-in-out',
  whiteSpace: 'nowrap',
  height: '90vh',
  fontSize: '10px',
  width: isSidebarCollapsed ? miniVariantWidth : drawerWidth,
  '& .sidebar-item-text': {
    display: isSidebarCollapsed ? 'none !important' : 'block',
    marginLeft: '10px',
    fontSize: '14px',
  },
  '@media screen and (max-width: 768px)': {
    width: isSidebarCollapsed ? miniVariantWidth : 0,
  },
  overflowY: 'hidden',
  '&:hover': {
    overflowY: 'scroll !important',
    '::-webkit-scrollbar': { width: '3px' },
    '::-webkit-scrollbar-thumb': { background: '#b3b3b3', borderRadius: '10px' },
  },
});

export const getListItemStyles = isActivePath => ({
  backgroundColor: isActivePath ? colors.grey200 : 'inherit',
  borderRadius: '20px',
  textAlign: 'center',
});
