export default function themePalette(theme) {
  return {
    primary: {
      main: theme.colors?.primaryMain,
    },
    secondary: {
      main: theme.colors?.secondaryMain,
    },
    warning: {
      main: theme.colors?.warningMain,
    },
    success: {
      main: theme.colors?.successMain,
    },
  };
}
