// this file will contain global constants for the app with uppercase format

export const API_URL = process.env.REACT_APP_API_URL;

// PAGINATION
export const LIMIT_KEY = 'limit';
export const PAGE_KEY = 'page';
export const OFFSET_KEY = 'offset';

export const drawerWidth = 260;

export const bannerPositions = [
  { diemsion: '100px height', value: 'first_banner', type: 'benner' },
  { diemsion: '300*90', value: 'second_banner', type: 'benner' },
  { diemsion: '360*90', value: 'third_banner', type: 'benner' },
  { diemsion: '360*200', value: 'fourth_banner', type: 'benner' },
  { diemsion: '360*200', value: 'fourth_banner', type: 'article_banner' },
];

export const usersCsvHeader = [
  { label: 'User ID', key: 'id' },
  { label: 'Name', key: 'name' },
  { label: 'Username', key: 'username' },
  { label: 'Email', key: 'email' },
  { label: 'User Type', key: 'userType' },
  { label: 'Author Rank', key: 'rank' }
];
