import React, { useState } from 'react';
import { AppBar, Toolbar, Box, Avatar, Typography, Menu, MenuItem, ListItemIcon } from '@mui/material';
import { ArrowDropDown, Person, Logout } from '@mui/icons-material';
import { Link } from 'react-router-dom';

import useAuth from 'customHooks/useAuth';
import logo from 'assets/images/Main-Logo-Blue.png';
import avatarImage from 'assets/images/avatar.png';
import { navBarStyle } from 'styles/mui/common/layout-styles';
import { navMenuPositionProps } from 'utilities/helperProps';

function Navbar() {
  const [anchorEl, setAnchorEl] = useState(null);
  const { handleLogout, UserProfile } = useAuth();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="sticky" sx={navBarStyle}>
      <Toolbar>
        <Box component={Link} to="/" sx={{ maxWidth: '55px' }}>
          <img className="img-fluid" src={logo} alt="Logo" />
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <Box className="d-flex align-items-center pointer" onClick={handleClick}>
          <Avatar alt="Remy Sharp" src={avatarImage} />
          <Typography className="mx-1">
            {UserProfile?.first_name} {UserProfile?.last_name}
          </Typography>
          <ArrowDropDown />
        </Box>
      </Toolbar>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        key={!!anchorEl}
        onClose={handleClose}
        disableScrollLock
        {...navMenuPositionProps}
        PaperProps={{
          style: {
            width: 170,
            marginTop: '20px',
          },
        }}
      >
        <MenuItem component={Link} to="/profile">
          <ListItemIcon>
            <Person fontSize="small" />
          </ListItemIcon>
          My Profile
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </AppBar>
  );
}

export default Navbar;
