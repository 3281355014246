// assets
import colors from 'assets/scss/_themes-vars.module.scss';
import themePalette from './palette';

const { createTheme } = require('@mui/material');

const color = colors;

const themeOption = {
  colors: color,
  // heading: color.grey900,
  // paper: color.paper,
  // backgroundDefault: color.paper,
  // background: color.primaryLight,
  divider: color.grey200,
};

// CUSTOMIZE MUI THEME HERE!
const theme = createTheme({
  typography: {
    fontFamily: 'Poppins, sans-serif',
    allVariants: {
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 400,
    },
    h1: {
      fontSize: '52px',
      fontWeight: '500',

      '@media (min-width: 768px) and (max-width: 991px)': {
        fontSize: '45px',
      },

      '@media (max-width: 768px) and (min-width: 575px)': {
        fontSize: '38px',
      },

      '@media (max-width: 575px)': {
        fontSize: '34px',
      },
    },

    h2: {
      fontSize: '42px',
      fontWeight: '500',
      textTransform: 'capitalize',

      '@media (min-width: 768px) and (max-width: 991px)': {
        fontSize: '38px',
      },

      '@media (max-width: 768px) and (min-width: 575px)': {
        fontSize: '32px',
      },

      '@media (max-width: 575px)': {
        fontSize: '30px',
      },
    },

    h3: {
      fontSize: '38px',
      fontWeight: '500',

      '@media (min-width: 768px) and (max-width: 991px)': {
        fontSize: '32px',
      },

      '@media (max-width: 768px) and (min-width: 575px)': {
        fontSize: '28px',
      },

      '@media (max-width: 575px)': {
        fontSize: '24px',
      },
    },

    h4: {
      fontSize: '34px',
      fontWeight: '500',

      '@media (min-width: 768px) and (max-width: 991px)': {
        fontSize: '28px',
      },

      '@media (max-width: 768px) and (min-width: 575px)': {
        fontSize: '24px',
      },

      '@media (max-width: 575px)': {
        fontSize: '20px',
      },
    },

    h5: {
      fontSize: '24px',
      fontWeight: '400',

      '@media (min-width: 768px) and (max-width: 991px)': {
        fontSize: '23px',
      },

      '@media (max-width: 768px) and (min-width: 575px)': {
        fontSize: '22px',
      },

      '@media (max-width: 575px)': {
        fontSize: '20px',
      },
    },
    h6: {
      fontSize: '20px',
      fontWeight: '400',

      '@media (min-width: 768px) and (max-width: 991px)': {
        fontSize: '18px',
      },

      '@media (max-width: 768px) and (min-width: 575px)': {
        fontSize: '16px',
      },

      '@media (max-width: 575px)': {
        fontSize: '16px',
      },
    },
    label: {
      display: 'block',
      fontSize: '0.9rem',
      fontWeight: 400,
      marginBottom: '6px !important',
    },
    pageTitle: {
      fontWeight: 500,
      textTransform: 'capitalize',
      fontSize: '20px',
      display: 'block',
    },
    subHead: {
      marginBottom: '20px',
      fontSize: '14px',
      opacity: 0.7,
    },
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'contained', color: 'primary' },
          style: {
            textTransform: 'none',
            borderRadius: '5px',
            transition: 'all 0.4s',
            background: color.primary,
            border: '1px solid transparent',
            color: '#fff !important',
          },
        },
        {
          props: { variant: 'contained', color: 'error' },
          style: {
            textTransform: 'none',
            borderRadius: '5px',
            transition: 'all 0.4s',
            border: '1px solid transparent',
          },
        },
        {
          props: { variant: 'contained', color: 'success' },
          style: {
            textTransform: 'none',
            color: '#fff !important',
            borderRadius: '5px',
            transition: 'all 0.4s',
            border: '1px solid transparent',
          },
        },
        {
          props: { variant: 'contained', color: 'secondary' },
          style: {
            textTransform: 'none',
            borderRadius: '5px',
            transition: 'all 0.4s',
            background: color.secondary,
            color: '#fff',
            border: '1px solid transparent',
            '&:hover': {
              background: color.dark,
              color: color.secondary,
            },
          },
        },
      ],
    },

    MuiCard: {
      styleOverrides: {
        root: {
          background: 'white',
          borderRadius: '5px',
          padding: '10px 20px',
          boxShadow: '2px 2px 10px #d7d7d7',
          border: 'none',
        },
      },
    },

    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: 'rgba(0, 0, 0, 0.5)',
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1400,
    },
  },

  palette: themePalette(themeOption),
});

export default theme;
