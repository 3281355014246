import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

export default function PrivateRoute() {
  const { pathname } = useLocation();

  const { isAuthenticated } = useSelector(reduxState => reduxState.auth);
  return !isAuthenticated ? <Navigate to="/auth/login" state={{ from: pathname }} replace /> : <Outlet />;
}
