import React, { useState, useEffect } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { Outlet } from 'react-router-dom';

// COMPONENTS
import { getBoxWrapperStyles, getSidebarWrapperStyles } from 'styles/mui/common/layout-styles';
import Sidebar from './components/sidebar';
import Navbar from './components/Navbar';

function LayoutWrapper() {
  const [isSidebarCollapsed, setSidebarCollapsed] = useState(false);
  const isSmallDevice = useMediaQuery('@media screen and (max-width: 768px)');
  const toggleSidebarCollapse = () => {
    if (!isSmallDevice) {
      setSidebarCollapsed(!isSidebarCollapsed);
    }
  };

  useEffect(() => {
    if (!isSmallDevice) {
      setSidebarCollapsed(false);
    } else {
      setSidebarCollapsed(true);
    }
  }, [isSmallDevice]);

  const drawerWidth = '240px';
  return (
    <Box>
      <Navbar toggleSidebar={toggleSidebarCollapse} />
      <Box className="d-flex">
        <Box sx={getSidebarWrapperStyles(isSidebarCollapsed, drawerWidth)}>
          <Sidebar toggleSidebar={toggleSidebarCollapse} />
        </Box>
        <Box sx={getBoxWrapperStyles(isSidebarCollapsed, drawerWidth)}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
}

export default LayoutWrapper;
