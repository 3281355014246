import React from 'react';
import { ListItem, ListItemButton, Typography } from '@mui/material';
import { Link, useMatch } from 'react-router-dom';
import propTypes from 'prop-types';

import { getListItemStyles } from 'styles/mui/common/layout-styles';

function SidebarItem({ icon, path, title }) {
  const isActivePath = useMatch(path || '');
  return (
    <ListItem>
      <ListItemButton component={Link} to={path} sx={getListItemStyles(!path ? false : isActivePath)}>
        {icon}
        <Typography className="sidebar-item-text">{title}</Typography>
      </ListItemButton>
    </ListItem>
  );
}

SidebarItem.propTypes = {
  icon: propTypes.any.isRequired,
  path: propTypes.string.isRequired,
  title: propTypes.string.isRequired,
};

export default SidebarItem;
