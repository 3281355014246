// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

a {
  text-decoration: none !important;
  color: inherit !important;
}

p {
  margin-bottom: 0 !important;
}

.pointer {
  cursor: pointer;
}

.required::after {
  content: "*";
  color: red;
}

.breakChildrenTextAnywhere p,
.breakChildrenTextAnywhere h1,
.breakChildrenTextAnywhere h2,
.breakChildrenTextAnywhere h3,
.breakChildrenTextAnywhere h4,
.breakChildrenTextAnywhere h5,
.breakChildrenTextAnywhere h5 {
  line-break: anywhere;
}

.pageContainer {
  border-radius: 20px;
  background-color: #fff;
  min-height: 100%;
  padding: 20px;
}

.textCapitalize {
  text-transform: capitalize;
}

.ck-editor__editable[role=textbox] {
  min-height: 400px;
}`, "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AAEA;EACE,SAAA;EACA,UAAA;EACA,sBAAA;EACA,kCAAA;AAAF;;AAGA;EACE,gCAAA;EACA,yBAAA;AAAF;;AAGA;EACE,2BAAA;AAAF;;AAGA;EACE,eAAA;AAAF;;AAIE;EACE,YAAA;EACA,UAAA;AADJ;;AAME;;;;;;;EAOE,oBAAA;AAHJ;;AAOA;EACE,mBAAA;EACA,sBAAA;EACA,gBAAA;EACA,aAAA;AAJF;;AAOA;EACE,0BAAA;AAJF;;AAOA;EACE,iBAAA;AAJF","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');\n\nbody {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n  font-family: 'Poppins', sans-serif;\n}\n\na {\n  text-decoration: none !important;\n  color: inherit !important;\n}\n\np {\n  margin-bottom: 0 !important;\n}\n\n.pointer {\n  cursor: pointer;\n}\n\n.required {\n  &::after {\n    content: '*';\n    color: red;\n  }\n}\n\n.breakChildrenTextAnywhere {\n  & p,\n  h1,\n  h2,\n  h3,\n  h4,\n  h5,\n  h5 {\n    line-break: anywhere;\n  }\n}\n\n.pageContainer {\n  border-radius: 20px;\n  background-color: #fff;\n  min-height: 100%;\n  padding: 20px;\n}\n\n.textCapitalize{\n  text-transform: capitalize\n}\n\n.ck-editor__editable[role='textbox'] {\n  min-height: 400px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
