import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import GlobalLoader from 'containers/common/loaders/GlobalLoader';
import LayoutWrapper from 'containers/common/layout';
import PublicRoutes from './PublicRoutes';
import PrivateRoutes from './PrivateRoutes';
import AdminRoutes from './AdminRoutes';

// No permission
const NoPermission = lazy(() => import('containers/pages/forbidenPage'));

// PAGES HERE LIKE BELOW
const Dashboard = lazy(() => import('containers/pages/dashboard'));
// AUTH PAGES
const Login = lazy(() => import('containers/pages/auth/login'));
const ForgotPassword = lazy(() => import('containers/pages/auth/forgotPassword'));
const OTPVerification = lazy(() => import('containers/pages/auth/forgotPassword/OtpVerification'));

// Profile
const Profile = lazy(() => import('containers/pages/auth/profile'));

// Private Pages
const UserTracking = lazy(() => import('containers/pages/userTracking'));
const UserAccountManagement = lazy(() => import('containers/pages/userAccounts'));
const UserStatistics = lazy(() => import('containers/pages/userStatistics'));
const PointsManagement = lazy(() => import('containers/pages/pointsManagement'));
// Coupons
const Coupons = lazy(() => import('containers/pages/coupons'));
const AddCoupon = lazy(() => import('containers/pages/coupons/AddCoupon'));
const UpdateCoupon = lazy(() => import('containers/pages/coupons/UpdateCoupon'));

// subscription
const Subscriptions = lazy(() => import('containers/pages/subscriptions'));

// points purchasing packages
const PointsPurchasingPackages = lazy(() => import('containers/pages/pointsPurchasing'));
const AddPointsPurchasingPackages = lazy(() => import('containers/pages/pointsPurchasing/AddPackage'));
const UpdatePointsPurchasingPackages = lazy(() => import('containers/pages/pointsPurchasing/EditPackage'));

// blogs and events
const BlogsAndEvents = lazy(() => import('containers/pages/blogsAndEvents/index'));
const AddBlog = lazy(() => import('containers/pages/blogsAndEvents/AddBlog'));
const UpdateBlog = lazy(() => import('containers/pages/blogsAndEvents/UpdateBlog'));
const AddEvent = lazy(() => import('containers/pages/blogsAndEvents/AddEvent'));
const UpdateEvent = lazy(() => import('containers/pages/blogsAndEvents/UpdateEvent'));

// admin  routes
const AdminAccounts = lazy(() => import('containers/pages/adminAuthority'));
const AddAdminAccount = lazy(() => import('containers/pages/adminAuthority/AddAdminAccount'));
const UpdateAdminAccount = lazy(() => import('containers/pages/adminAuthority/UpdateAdminAccount'));
const AdminActivity = lazy(() => import('containers/pages/adminActivity'));

// Categories and subCategories
const AllCategories = lazy(() => import('containers/pages/categories'));
const AddCategory = lazy(() => import('containers/pages/categories/AddCategory'));
const UpdateCategory = lazy(() => import('containers/pages/categories/UpdateCategory'));
const AddSubCategory = lazy(() => import('containers/pages/categories/AddSubcategory'));
const UpdateSubCategory = lazy(() => import('containers/pages/categories/UpdateSubCategory'));

// User level and articles
const ArticlesAndUserLevel = lazy(() => import('containers/pages/articleAndUserLevel'));
const UpdateArticle = lazy(() => import('containers/pages/articleAndUserLevel/UpdateArticle'));

// User Interesr
const Interest = lazy(() => import('containers/pages/interest'));
const AddInterest = lazy(() => import('containers/pages/interest/AddInterest'));
const UpdateInterest = lazy(() => import('containers/pages/interest/UpdateInterest'));

// Contact Us
const ContactUs = lazy(() => import('containers/pages/contactUs'));

// Brands
const Brands = lazy(() => import('containers/pages/brands'));

// Brands
const ThreshHolds = lazy(() => import('containers/pages/threshHolds'));
const UpdateDonationLimit = lazy(() => import('containers/pages/threshHolds/UpdateDonationLimit'));

// Rank Management
const RankManagement = lazy(() => import('containers/pages/rankManagement'));

// FAQS
const FAQS = lazy(() => import('containers/pages/faqs'));
const AddFaq = lazy(() => import('containers/pages/faqs/AddFaq'));
const UpdateFaq = lazy(() => import('containers/pages/faqs/UpdateFaq'));

// Author compensation
const Compensations = lazy(() => import('containers/pages/authorCompensation'));
const AddCompensation = lazy(() => import('containers/pages/authorCompensation/AddCompensation'));
const UpdateCompensation = lazy(() => import('containers/pages/authorCompensation/UpdateCompensation'));

// Privacy Policy and Temrs & Conditions
const PCAndTermsAndConditions = lazy(() => import('containers/pages/t&CAndPrivacyPolicy'));
// User level criteria
const UserLevelCriteria = lazy(() => import('containers/pages/userLevelCriteria'));
const UpdateUserLevelCriteria = lazy(
  () => import('containers/pages/userLevelCriteria/UpdateUserLevelCriteria')
);

// Landing Page
const LandingPageCustomization = lazy(() => import('containers/pages/landingPageCutomization'));
const AddBanner = lazy(() => import('containers/pages/landingPageCutomization/AddBanner'));
const UpdateBanner = lazy(() => import('containers/pages/landingPageCutomization/UpdateBanner'));
const AddSliderBanner = lazy(() => import('containers/pages/landingPageCutomization/AddRankBanner'));
const UpdateSliderBanner = lazy(() => import('containers/pages/landingPageCutomization/UpdateRankBanner'));

// Subscription packages Page
const SubscriptionsPackages = lazy(() => import('containers/pages/subscriptionsPackages'));
const AddSubscriptionPackage = lazy(() => import('containers/pages/subscriptionsPackages/AddPackage'));
const UpdateSubscriptionPackage = lazy(() => import('containers/pages/subscriptionsPackages/UpdatePackage'));

// pages meta data
const AllPagesMetadata = lazy(() => import('containers/pages/pagesMetaData'));
const AddMetadata = lazy(() => import('containers/pages/pagesMetaData/AddMetadata'));
const UpdateMetadata = lazy(() => import('containers/pages/pagesMetaData/UpdateMetadata'));

// pages header
const AllPagesHeader = lazy(() => import('containers/pages/pagesHeader'));
const UpdatePagesHeader = lazy(() => import('containers/pages/pagesHeader/UpdateHeader'));

function AppRoutes() {
  return (
    <BrowserRouter>
      <Suspense fallback={<GlobalLoader />}>
        <Routes>
          <Route path="auth" element={<PublicRoutes />}>
            <Route path="login" element={<Login />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="otp-verification" element={<OTPVerification />} />
          </Route>
          <Route path="/" element={<LayoutWrapper />}>
            <Route path="/" element={<PrivateRoutes />}>
              <Route index element={<Dashboard />} />
              <Route path="profile" element={<Profile />} />
              <Route path="user-tracking" element={<UserTracking />} />
              <Route path="user-account-management" element={<UserAccountManagement />} />
              <Route path="users-statistics" element={<UserStatistics />} />

              <Route path="points-management" element={<PointsManagement />} />
              <Route path="coupons" element={<Coupons />} />
              <Route path="coupons/add-coupon" element={<AddCoupon />} />
              <Route path="coupons/update-coupon/:couponId" element={<UpdateCoupon />} />

              <Route path="subscriptions" element={<Subscriptions />} />

              <Route path="points-purchasing" element={<PointsPurchasingPackages />} />
              <Route path="add-points-package" element={<AddPointsPurchasingPackages />} />
              <Route path="update-points-package/:id" element={<UpdatePointsPurchasingPackages />} />

              <Route path="blogs-and-events" element={<BlogsAndEvents />} />
              <Route path="add-blog" element={<AddBlog />} />
              <Route path="update-blog/:id" element={<UpdateBlog />} />
              <Route path="add-event" element={<AddEvent />} />
              <Route path="update-event/:id" element={<UpdateEvent />} />

              <Route path="articles" element={<ArticlesAndUserLevel />} />
              <Route path="update-article/:id" element={<UpdateArticle />} />

              <Route path="pages-header" element={<AllPagesHeader />} />
              <Route path="update-header/:id" element={<UpdatePagesHeader />} />

              <Route path="categories" element={<AllCategories />} />
              <Route path="add-category" element={<AddCategory />} />
              <Route path="update-category/:id" element={<UpdateCategory />} />
              <Route path="add-sub-category" element={<AddSubCategory />} />
              <Route path="update-sub-category/:id" element={<UpdateSubCategory />} />

              <Route path="interest" element={<Interest />} />
              <Route path="add-interest" element={<AddInterest />} />
              <Route path="update-interest/:id" element={<UpdateInterest />} />

              <Route path="compensations" element={<Compensations />} />
              <Route path="add-compensation" element={<AddCompensation />} />
              <Route path="update-compensation/:id" element={<UpdateCompensation />} />

              <Route path="subscription-packages" element={<SubscriptionsPackages />} />
              <Route path="add-subscription-package" element={<AddSubscriptionPackage />} />
              <Route path="update-subscription-package/:id" element={<UpdateSubscriptionPackage />} />

              <Route path="contact-us" element={<ContactUs />} />

              <Route path="brands" element={<Brands />} />

              <Route path="rank-management" element={<RankManagement />} />

              <Route path="faqs" element={<FAQS />} />
              <Route path="add-faq" element={<AddFaq />} />
              <Route path="update-faq/:id" element={<UpdateFaq />} />

              <Route path="pages-metadata" element={<AllPagesMetadata />} />
              <Route path="add-metadata" element={<AddMetadata />} />
              <Route path="update-metadata/:id" element={<UpdateMetadata />} />

              <Route path="landing-page-customization" element={<LandingPageCustomization />} />
              <Route path="add-banner" element={<AddBanner />} />
              <Route path="update-banner/:id" element={<UpdateBanner />} />
              <Route path="add-slider-banner" element={<AddSliderBanner />} />
              <Route path="update-slider-banner/:id" element={<UpdateSliderBanner />} />

              <Route path="privacy-policy-and-about-us" element={<PCAndTermsAndConditions />} />

              <Route path="user-level-criteria" element={<UserLevelCriteria />} />
              <Route path="update-user-level-criteria/:id" element={<UpdateUserLevelCriteria />} />

              <Route element={<AdminRoutes />}>
                <Route path="thresh-holds" element={<ThreshHolds />} />
                <Route path="update-donation-limit/:id" element={<UpdateDonationLimit />} />
              </Route>

              {/* Admin Routes */}
              <Route path="admin" element={<AdminRoutes />}>
                <Route path="admin-activity" element={<AdminActivity />} />
                <Route path="admin-accounts" element={<AdminAccounts />} />
                <Route path="add-admin-account" element={<AddAdminAccount />} />
                <Route path="update-admin-account/:id" element={<UpdateAdminAccount />} />
              </Route>

              {/* Not Found/ Not Permissin */}
              <Route path="admin/no-permission" element={<NoPermission />} />
              <Route path="no-permission" element={<NoPermission />} />
              <Route path="*" element={<h1>Not Found!</h1>} />
            </Route>
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default AppRoutes;
