import React, { useEffect } from 'react';
import { ThemeProvider } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';

// COMPONENTS & UTILS
import theme from 'styles/mui/theme';
import { useAuthorizedQuery } from 'services/private/user';
import { onAuthorized, onLoggedOut } from 'store/slices/authSlice';
import GlobalLoader from 'containers/common/loaders/GlobalLoader';
import AppRoutes from './routes';

function App() {
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector(reduxState => reduxState.auth);
  const { data, isSuccess, isLoading, isError } = useAuthorizedQuery();

  useEffect(() => {
    if (isSuccess) {
      dispatch(onAuthorized(data));
    } else if (isError) dispatch(onLoggedOut());
  }, [data, isLoading, isSuccess]);

  if (isAuthenticated === null) {
    return <GlobalLoader />;
  }

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        maxSnack={4}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        autoHideDuration={2000}
      >
        {!isLoading && <AppRoutes />}
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
