import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

function AdminRoutes() {
  const { pathname, state } = useLocation();
  const { user } = useSelector(reduxState => reduxState.auth);

  return user?.user_type === 'super_admin' ? (
    <Outlet />
  ) : (
    <Navigate to={state?.from || 'no-permission'} state={{ from: pathname }} replace />
  );
}

export default AdminRoutes;
