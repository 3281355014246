import React from 'react';
import { List } from '@mui/material';

import SidebarIItem from './SidebarIItem';
import { SidebarLinks } from '../../utilities/data';

function Sidebar() {
  return (
    <List className="sidebar">
      {SidebarLinks.map(link => (
        <SidebarIItem path={link.path} title={link.title} icon={link.icon} key={link.id} />
      ))}
    </List>
  );
}

export default Sidebar;
