/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isAuthenticated: null,
  user: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    onAuthorized: (state, { payload }) => {
      state.isAuthenticated = true;
      state.user = payload;
    },
    onLoggedIn: (state, { payload }) => {
      state.token = payload.access;
      state.isAuthenticated = true;
      state.user = payload.user;

      if (payload.access) {
        localStorage.setItem('token', payload.access);
      }
    },
    onLoggedOut: state => {
      state.isAuthenticated = false;
      state.user = null;
      state.token = null;
      localStorage.removeItem('token');
    },
  },
});

export const { onLoggedIn, onAuthorized, onLoggedOut } = authSlice.actions;
export default authSlice.reducer;
