/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */

import { v4 } from 'uuid';
import {
  Dashboard,
  AdminPanelSettings,
  Description,
  Rowing,
  AccountBox,
  Addchart,
  Scoreboard,
  LocalOffer,
  ShoppingCart,
  CardMembership,
  Assignment,
  AddHome,
  MilitaryTech,
  RoomPreferences,
  Category,
  Interests,
  Email,
  Gavel,
  Security,
  CardGiftcard,
  Dvr,
  Filter9Plus,
  PendingActions,
  Shop,
  DataObject,
  MenuBook,
} from '@mui/icons-material';

export const SidebarLinks = [
  {
    id: v4(),
    title: 'Dashboard',
    path: '/',
    icon: <Dashboard />,
  },
  {
    id: v4(),
    title: 'Admin Authority',
    path: 'admin/admin-accounts',
    icon: <AdminPanelSettings />,
  },
  {
    id: v4(),
    title: 'Admins Activity',
    path: 'admin/admin-activity',
    icon: <PendingActions />,
  },
  {
    id: v4(),
    title: 'User Tracking',
    path: 'user-tracking',
    icon: <Rowing />,
  },
  {
    id: v4(),
    title: 'User Accounts',
    path: 'user-account-management',
    icon: <AccountBox />,
  },
  {
    id: v4(),
    title: 'Users Statistics',
    path: 'users-statistics',
    icon: <Addchart />,
  },
  {
    id: v4(),
    title: 'All Brands',
    path: 'brands',
    icon: <Dvr />,
  },
  {
    id: v4(),
    title: 'Points Management',
    path: 'points-management',
    icon: <Scoreboard />,
  },
  {
    id: v4(),
    title: 'Coupons/Secret Box',
    path: 'coupons',
    icon: <LocalOffer />,
  },
  {
    id: v4(),
    title: 'Subscriptions',
    path: 'subscriptions',
    icon: <CardMembership />,
  },
  {
    id: v4(),
    title: 'Subscription Packages',
    path: 'subscription-packages',
    icon: <Shop />,
  },
  {
    id: v4(),
    title: 'Articles',
    path: 'articles',
    icon: <Assignment />,
  },
  {
    id: v4(),
    title: 'User Level Criteria',
    path: 'user-level-criteria',
    icon: <Gavel />,
  },
  {
    id: v4(),
    title: 'Landing Page',
    path: 'landing-page-customization',
    icon: <AddHome />,
  },
  {
    id: v4(),
    title: 'Rank Management',
    path: 'rank-management',
    icon: <MilitaryTech />,
  },
  {
    id: v4(),
    title: 'Points Purchasing',
    path: 'points-purchasing',
    icon: <ShoppingCart />,
  },
  {
    id: v4(),
    title: 'Blogs and Events',
    path: 'blogs-and-events',
    icon: <Description />,
  },
  {
    id: v4(),
    title: 'Thresholds',
    path: 'thresh-holds',
    icon: <Filter9Plus />,
  },
  {
    id: v4(),
    title: 'Catgories',
    path: 'categories',
    icon: <Category />,
  },
  {
    id: v4(),
    title: 'SEO Metadata',
    path: 'pages-metadata',
    icon: <DataObject />,
  },
  {
    id: v4(),
    title: 'Pages Header',
    path: 'pages-header',
    icon: <MenuBook />,
  },
  {
    id: v4(),
    title: 'FAQs',
    path: 'faqs',
    icon: <RoomPreferences />,
  },
  {
    id: v4(),
    title: 'Privacy Policy/About',
    path: 'privacy-policy-and-about-us',
    icon: <Security />,
  },
  {
    id: v4(),
    title: 'User Interest',
    path: 'interest',
    icon: <Interests />,
  },
  {
    id: v4(),
    title: 'Author Compensations',
    path: 'compensations',
    icon: <CardGiftcard />,
  },
  {
    id: v4(),
    title: 'Contact Us',
    path: 'contact-us',
    icon: <Email />,
  },
];

export const test = {};
